function ConsentModal({ closeModal }) {


    const headline = "ברוכים הבאים לאפליקצית לוטומטי"
    const text = "יש לבחור מתחת או מעל ל-6 מספרים ואת מספר הטורים שברצונך לתת למערכת למלא.  (מספרים חזקים נבחרים רנדומלית ללא אפשרות בחירה)."
    const text2 = "המערכת תמלא את הטבלאות אך ורק עם המספרים שבחרת. במידה ובחרת פחות מ-6 מספרים, המערכת תשלים לבדה את המספרים הנוספים."
    const waiverText = "המערכת מגרילה מספרים באופן רנדומלי ע\"י מחשב ואין להסתמך על התוצאות כהבטחה לזכייה בפרס כלשהו."
    const waiverText2 = "במקרים נדירים ייתכן והמערכת תמלא טבלאות זהות."
    const consentText = "אני מאשר שקראתי ושאני מעל גיל 18"

    return (
        <div dir="rtl" className="flex flex-col p-4 w-96 text-black bg-lottobg absolute place-self-center z-20 md:w-64 ">
            <p className="self-center text-xl font-bold text-blue">{headline}</p><br />
            <p className="font-semibold">{text}</p><br />
            <p className="font-semibold">{text2}</p><br />
            <p className="text-sm">*{waiverText}</p>
            <p className="text-sm">**{waiverText2}</p><br />
            <p className="cursor-pointer text-center bg-lotto text-white p-2" onClick={closeModal}>{consentText}</p>
        </div>
    )
}



export default ConsentModal;



