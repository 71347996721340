import { useState, useEffect } from 'react';
import './App.css';
import RandomBox from './comps/RandomBox';
import { getNumbers, shuffleNumbers, shuffleMoreNumbers } from './functions';
import Modal from './comps/Modal';
import { BsPlayFill } from "react-icons/bs"
import BlackSquares from './comps/BlackSquares';

function Box() {

  const [numbers, setNumbers] = useState([])
  const [checkedNumbers, setCheckedNumbers] = useState([])
  const [chosenNumber, setChosenNumber] = useState(0)
  const [message, setMessage] = useState('')
  const [boxes, setBoxes] = useState([])
  const [openModal, setOpenModal] = useState(false)

  const numberBoxes = [1, 2, 4, 10, 14]

  useEffect(() => {
    getNumbersFromFile()
  }, [])

  const getNumbersFromFile = () => {
    const data = getNumbers()
    setNumbers(data)
  }

  const selectNumbers = (ev) => {
    const updatedNumbers = numbers.map(num => {
      if (num.number === ev.target.value) {
        return { ...num, checked: ev.target.checked }
      }
      return num;
    });

    setNumbers(updatedNumbers)

    if (ev.target.checked) {
      const updatedList = [...checkedNumbers, ev.target.value]
      setCheckedNumbers(updatedList)
    } else {
      const deletedNumbers = checkedNumbers.filter(num => {
        return num !== ev.target.value
      })
      setCheckedNumbers(deletedNumbers)
    }
  }

  const chooseNumber = (ev) => {
    setChosenNumber(ev.target.value)
  }

  const generateNumbers = () => {

    // if (!checkedNumbers.length) {
    //   setMessage('יש לבחור מספר אחד לפחות')
    //   setOpenModal(true)
    // } else 
    // the lines above removed in order to allow to fill a form without choosing any numbers at all
    if (checkedNumbers.length <= 5) {
      setMessage('יש לבחור מספר טורים למילוי')
      setOpenModal(true)
      if (chosenNumber > 0) {
        setMessage('')
        setOpenModal(false)
        const numbers = shuffleNumbers(checkedNumbers, chosenNumber)
        setBoxes(numbers)
      }
    } else if (checkedNumbers.length === 6) {
      setMessage('יש לבחור מעל או מתחת ל-6 מספרים')
      setOpenModal(true)
    } else if (checkedNumbers.length > 6 && checkedNumbers.length <= 37) {
      setMessage('יש לבחור מספר טורים למילוי')
      setOpenModal(true)
      if (chosenNumber > 0) {
        setMessage('')
        setOpenModal(false)
        // calling another function 
        const numbers = shuffleMoreNumbers(checkedNumbers, chosenNumber)
        setBoxes(numbers)
      }
    } else {
      setMessage('אירעה שגיאה. אנא נסה.י שנית!');
      setOpenModal(true)
    };
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const renderTable = numbers.map((item, index) => {
    return <div className={`flex flex-col px-2 items-center box${item.number}`} key={index}>
      <p className="text-lotto font-semibold">{item.number}</p>
      <label className="box cursor-pointer mr-3" htmlFor={`box${item.number}`}>
        <input type="checkbox" id={`box${item.number}`} onChange={selectNumbers} value={item.number} checked={item.checked}></input>
        <span className="checkmark live"></span>
      </label>
    </div>
  });

  const renderBoxesSelection = numberBoxes.map((box, index) => {
    return <div className="flex flex-col px-2 items-center" key={index}>
      <p className="text-lotto font-semibold">{box}</p>
      <label className="box cursor-pointer mr-3">
        <input type="radio" name='box-number' value={box} onChange={chooseNumber} ></input>
        <span className="checkmark live"></span>
      </label>
    </div>
  });

  const renderGeneratedBoxes = boxes.map((box, index) => {
    return <RandomBox randomNumbers={box} serial={index} key={index} />
  });

  return (
    <div className='children mb-20 '>
      <div className="mb-4 flex flex-col items-center ">
        <div className='flex'>
          <div className='sm:hidden' >
            <BlackSquares />
          </div>
          <div className="form grid grid-cols-10 w-100 bg-lottobg">
            <div className='red-box flex justify-between pl-6 pr-2 items-center text-white text-center font-semibold bg-lotto h-8 mt-1'>
              <p className="text-lg xxs:text-sm ">בחר.י מספרים</p>
              {<BsPlayFill className='text-2xl xs:text-xl' />}
            </div>
            {renderTable}
          </div>
          <div className='sm:hidden'>
            <BlackSquares />
          </div>
        </div>
        <div className='flex flex-col mt-1'>
          <p className='text-lotto text-center text-lg font-semibold'>בחר.י מספר טורים למילוי</p>
          <div className='flex'>
            {renderBoxesSelection}
          </div>
        </div>
        <input type="button" className='bg-lotto text-white text-lg font-semibold w-60 xxs:w-48 cursor-pointer mt-2 h-8 button' value="מלא לי טופס" onClick={generateNumbers} />
      </div>
      {openModal &&
        <div className='h-full flex justify-center'>
          <div className='bg-black h-full w-full top-0 fixed opacity-80 z-10'></div>
          <Modal message={message} closeModal={closeModal} />
        </div>
      }
      <div className='border-2'>
        {renderGeneratedBoxes}
      </div>
    </div>
  );
}

export default Box;
