import { useEffect, useState } from 'react';
import { inject } from '@vercel/analytics';
import './App.css';
import Box from './Box';
import ConsentModal from './comps/ConsentModal';


function App() {
  inject();

  const [openModal, setOpenModal] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      showModal()
    }, 1000)
  }, [])

  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }


  return (
    <div className={`flex flex-col items-center justify-center text-center `}>
      <p dir="rtl" className='text-blue text-6xl font-bold py-2'>
        <span className='text-lotto'>לוטו</span>
        מטי!</p>
      <Box />
      {openModal &&
        <div className='h-full flex justify-center relative xs:absolute xs:mt-10'>
          <div className='bg-black h-full w-full top-0 fixed opacity-80'></div>
          <ConsentModal closeModal={closeModal} />
        </div>
      }
    </div>
  );
}

export default App;
