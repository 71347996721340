import { MdRectangle } from "react-icons/md"

function BlackSquares() {

    return (
        <div>
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
            <MdRectangle className='text-black' />
        </div>
    )
}



export default BlackSquares;



