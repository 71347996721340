import '../App.css'
import { shuffleAdditionalNumber } from '../functions'
import { useEffect, useState } from 'react'
import { BsPlayFill } from "react-icons/bs"

function RandomBox({ randomNumbers, serial }) {

  const numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37"]

  const additionalNumbers = ["1", "2", "3", "4", "5", "6", "7"]

  const [additionalNumber, setAdditionalNumber] = useState(null)

  useEffect(() => {
    const number = shuffleAdditionalNumber()
    setAdditionalNumber(number)
  }, [randomNumbers])

  const renderNumbers = numbers.map((item, index) => {
    if (randomNumbers.includes(item)) {
      return <div className=' flex flex-col px-2 items-center' key={index}>
        <p className='text-lotto font-semibold'>{item}</p>
        <label className='box mr-3' >
          <input type="checkbox" id={`box${item}`} checked disabled ></input>
          <span className='checkmark'></span>
        </label>
      </div>
    } else {
      return <div className=' flex flex-col px-2 items-center' key={index}>
        <p className='text-lotto font-semibold'>{item}</p>
        <label className='box mr-3' >
          <input type="checkbox" id={`box${item}`} checked={false} disabled></input>
          <span className={`checkmark ${((serial + 1) % 2 > 0) ? '' : 'checkmark-even'}`}></span>
        </label>
      </div>
    }
  });

  const renderAdditionalNumbers = additionalNumbers.map((item, index) => {
    if (additionalNumber == item) {
      return <div className={`flex flex-col px-2 items-center box-additional-${index + 1}`} key={index}>
        <p className='text-lotto font-semibold'>{item}</p>
        <label className='box mr-3' >
          <input type="checkbox" id={`box${item}`} checked disabled ></input>
          <span className='checkmark'></span>
        </label>
      </div>
    } else {
      return <div className={`flex flex-col px-2 items-center box-additional-${index + 1}`} key={index}>
        <p className='text-lotto font-semibold'>{item}</p>
        <label className='box mr-3' >
          <input type="checkbox" id={`box${item}`} checked={false} disabled></input>
          <span className={`checkmark ${((serial + 1) % 2 > 0) ? '' : ''}`}></span>
        </label>
      </div>
    }
  });


  return (
    <div className='flex sm:flex-col'>
      <div className={`form grid grid-cols-10 w-100 border-solid border-r-2 border-lotto ${((serial + 1) % 2 > 0) ? 'bg-lottobg' : 'bg-white'} sm:bg-white`}>
        <div className='flex justify-between pl-10  pr-2 items-center red-box text-white text-center font-semibold bg-lotto h-8 mt-1'>
          <div className='text-lg xxs:text-base'>
            <span> טבלה</span> {serial + 1}
          </div>
          {<BsPlayFill className='text-2xl xs:text-xl' />}
        </div>
        {renderNumbers}
      </div>
      <div className={`form-additional grid w-24 sm:w-100 grid-cols-2  ${((serial + 1) % 2 > 0) ? 'bg-white' : 'bg-lottobg'} sm:bg-lottobg`}>
        <div className='red-box-additional flex justify-around items-center text-white text-lg font-semibold bg-lotto h-8 mt-1'>
          <span className='hidden sm:block xxs:text-base'> המספר החזק</span>
          {<BsPlayFill className='text-2xl xs:text-xl' />}
        </div>
        {renderAdditionalNumbers}
      </div>
    </div>
  );
}

export default RandomBox;
