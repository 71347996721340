
const numbers = [
  {
    number: "1",
    checked: false
  },
  {
    number: "2",
    checked: false
  },
  {
    number: "3",
    checked: false
  },
  {
    number: "4",
    checked: false
  },
  {
    number: "5",
    checked: false
  },
  {
    number: "6",
    checked: false
  },
  {
    number: "7",
    checked: false
  },
  {
    number: "8",
    checked: false
  },
  {
    number: "9",
    checked: false
  },
  {
    number: "10",
    checked: false
  },
  {
    number: "11",
    checked: false
  },
  {
    number: "12",
    checked: false
  },
  {
    number: "13",
    checked: false
  },
  {
    number: "14",
    checked: false
  },
  {
    number: "15",
    checked: false
  },
  {
    number: "16",
    checked: false
  },
  {
    number: "17",
    checked: false
  },
  {
    number: "18",
    checked: false
  },
  {
    number: "19",
    checked: false
  },
  {
    number: "20",
    checked: false
  },
  {
    number: "21",
    checked: false
  },
  {
    number: "22",
    checked: false
  },
  {
    number: "23",
    checked: false
  },
  {
    number: "24",
    checked: false
  },
  {
    number: "25",
    checked: false
  },
  {
    number: "26",
    checked: false
  },
  {
    number: "27",
    checked: false
  },
  {
    number: "28",
    checked: false
  },
  {
    number: "29",
    checked: false
  },
  {
    number: "30",
    checked: false
  },
  {
    number: "31",
    checked: false
  },
  {
    number: "32",
    checked: false
  },
  {
    number: "33",
    checked: false
  },
  {
    number: "34",
    checked: false
  },
  {
    number: "35",
    checked: false
  },
  {
    number: "36",
    checked: false
  },
  {
    number: "37",
    checked: false
  }
]

const getNumbers = () => {
  return numbers
}


const shuffleNumbers = (inputArray, numberOfArrays) => {
  const resultArrays = [];

  for (let i = 0; i < numberOfArrays; i++) {
    const validNumbers = new Set([...inputArray]);
    const newArray = [...inputArray];


    while (newArray.length < 6) {
      const randomNumber = String(Math.floor(Math.random() * 37) + 1);
      if (!validNumbers.has(randomNumber)) {
        newArray.push(randomNumber);
        validNumbers.add(randomNumber);
      }
    }

    resultArrays.push(newArray);
  }

  return resultArrays;
};

const shuffleAdditionalNumber = () => {

  let randomNumber = Math.floor((Math.random() * 7) + 1);

  return randomNumber;
}


const shuffleMoreNumbers = (inputArray, numberOfArrays) => {
  const resultArrays = [];

  for (let i = 0; i < numberOfArrays; i++) {
    const newInputArray = [...inputArray];
    const newArray = [];

    while (newArray.length < 6) {
      const randomNumber = Math.floor(Math.random() * newInputArray.length);
      if (!newArray.includes(newInputArray[randomNumber])) {
        newArray.push(newInputArray[randomNumber]);
      }
    }

    resultArrays.push(newArray);
  }

  return resultArrays;
}

export {
  getNumbers,
  shuffleNumbers,
  shuffleAdditionalNumber,
  shuffleMoreNumbers
}