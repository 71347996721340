function Modal({ message, closeModal }) {

    return (
        <div dir="rtl" className="flex flex-col p-4 w-96 text-black bg-lottobg fixed left-1/2 top-1/2 z-20 translate md:w-64">
            <p className="text-lg font-semibold text-center">{message}</p><br />
            <p className="w-fit text-sm self-center cursor-pointer text-center bg-lotto text-white p-2" onClick={closeModal}>הבנתי</p>
        </div>
    )
}


export default Modal;



